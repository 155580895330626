import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import EPC from './components/EPC';
import Consultancy from './components/Consultancy';
import About from './components/About';
import Home from './components/Home';
import './index.css'; // Import the CSS file

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/epc" component={EPC} />
          <Route path="/consultancy" component={Consultancy} />
          <Route path="/about" component={About} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;