import React from 'react';
import '../index.css'; // Import the CSS file

function About() {
  return (
    <div id="root">
      {/* Header */}
      <header className="App-header">
        <h1>
          <a href="/" className="logo-container">
            <img src="images/databuilder-textless-logo.png" alt="Databuilder Limited" className="logo-image" />
            <span>Databuilder.</span>
          </a>
        </h1>
        <ul className="nav-links">
          <li><a href="/epc">EPC Complete</a></li>
          <li><a href="/consultancy">Consultancy Services</a></li>
          <li><span className="current-page">About Us</span></li>
        </ul>
      </header>

      {/* About Hero */}
      <section className="hero about-hero">
        <div className="hero-content">
          <h2>Our Story</h2>
          <p>Building better places through data innovation and expertise</p>
        </div>
      </section>

      {/* Company Story */}
      <section className="story">
        <div className="container">
          <h3>Why Databuilder</h3>
          <p>Founded in 2024, Databuilder emerged from a vision to transform how organisations leverage data for property insights. With over a decade of combined experience in data science and strategy, we recognised the need for innovative solutions in the real estate and the wider public sector.</p>
        </div>
      </section>

      {/* Team Section */}
      <section className="team">
        <div className="container">
          <h3>Meet Our Founder</h3>
          <div className="founders-grid">
            <div className="founder-card">
              <div className="founder-image">
                <img src="images/tom.jpg" alt="Tom Foster" />
              </div>
              <h4>Tom Foster</h4>
              <p className="title">Founder and Chief Data Strategist</p>
              <p className="bio">With over a decade of experience in data and AI, Tom leads our technical innovation. Previously leading data science corporately, he brings expertise in machine learning and data strategy. He's recognised as a Sector Expert for Local Government, and an Industry Expert by HM Government.</p>
              <div className="experience">
                <ul>
                  <li>10+ years in data science and strategy</li>
                  <li>Expert in AI/ML implementation</li>
                  <li>Industry and Sector Expert</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="App-footer">
        <div className="footer-content">
          <div className="footer-cta">
            <h3>Ready to Transform with AI?</h3>
            <a href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20Databuilder's%20Services" className="button primary">Get in Touch</a>
          </div>
          <p>© 2025 Databuilder Limited. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default About;
