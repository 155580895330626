import React from 'react';
import '../index.css'; // Import the CSS file

function Home() {
  return (
    <div id="root">
      {/* Header */}
      <header className="App-header">
        <h1>
          <a href="/" className="logo-container">
            <img src="images/databuilder-textless-logo.png" alt="Databuilder Limited" className="logo-image" />
            <span>Databuilder.</span>
          </a>
        </h1>
        <ul className="nav-links">
          <li><a href="/epc">EPC Complete</a></li>
          <li><a href="/consultancy">Consultancy Services</a></li>
          <li><a href="/about">About Us</a></li>
        </ul>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h2>Building Better Places With AI</h2>
          <p>Harness AI and Data to drive innovation, efficiency, and growth</p>
          <div className="button-group">
            <a className="button primary" href="/consultancy">Discover Our Services</a>
            <a className="button secondary" href="/epc">EPC Complete</a>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <main className="App-main" id="services">
        <section className="service">
          <div className="service-content">
            <h2>AI and Machine Learning Solutions</h2>
            <p>Custom models built for efficiency in sectors like finance, healthcare, and logistics.</p>
          </div>
        </section>
        <section className="service">
          <div className="service-content">
            <h2>Data Strategy and Governance</h2>
            <p>Align data initiatives with organisational objectives and maintain regulatory compliance.</p>
          </div>
        </section>
        <section className="service">
          <div className="service-content">
            <h2>Consultancy and Interim Leadership</h2>
            <p>Interim CDO services, hands-on support, and strategic advice to accelerate data maturity.</p>
          </div>
        </section>
      </main>

      <footer className="App-footer">
        <div className="footer-content">
          <div className="footer-cta">
            <h3>Ready to Transform Your Data Strategy?</h3>
            <a href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20Databuilder's%20Services" className="button primary">Get in Touch</a>
          </div>
          <p>© 2025 Databuilder Limited. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
