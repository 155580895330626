import React from 'react';
import '../index.css'; // Import the CSS file

function Consultancy() {
  return (
    <div id="root">
      {/* Header */}
      <header className="App-header">
        <h1>
          <a href="/" className="logo-container">
            <img src="images/databuilder-textless-logo.png" alt="Databuilder Limited" className="logo-image" />
            <span>Databuilder.</span>
          </a>
        </h1>
        <ul className="nav-links">
          <li><a href="/epc">EPC Complete</a></li>
          <li><span className="current-page">Consultancy Services</span></li>
          <li><a href="/about">About Us</a></li>
        </ul>
      </header>

      {/* Consultancy Hero */}
      <section className="hero consultancy-hero">
        <div className="hero-content">
          <h2>AI and Data Consultancy Services</h2>
          <p>Strategic guidance and hands-on expertise to accelerate your AI and data transformation journey</p>
          <div className="button-group">
            <a className="button primary" href="#services">Our Services</a>
            <a className="button secondary" href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20Consultancy%20Services">Contact Us</a>
          </div>
        </div>
      </section>

      {/* Service Offerings */}
      <section className="services" id="services">
        <div className="container">
          <h3>Our Services</h3>
          <div className="service-grid">
            <div className="service-card">
              <h4>Strategy Development</h4>
              <p>Develop comprehensive data and AI strategies aligned with your business objectives</p>
              <ul className="service-details">
                <li>Data maturity assessment</li>
                <li>Technology roadmap</li>
                <li>Implementation planning</li>
              </ul>
            </div>
            <div className="service-card">
              <h4>Interim CDO Services</h4>
              <p>Expert leadership to drive your data initiatives forward</p>
              <ul className="service-details">
                <li>Strategic direction</li>
                <li>Team leadership</li>
                <li>Governance implementation</li>
              </ul>
            </div>
            <div className="service-card">
              <h4>AI Implementation</h4>
              <p>End-to-end support for AI and machine learning projects</p>
              <ul className="service-details">
                <li>Use case identification</li>
                <li>Model development</li>
                <li>Production deployment</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Methodology */}
      <section className="methodology">
        <div className="container">
          <h3>Our Approach</h3>
          <div className="methodology-steps">
            <div className="step">
              <span className="step-number">1</span>
              <h4>Assess</h4>
              <p>Comprehensive evaluation of your current data landscape and objectives</p>
            </div>
            <div className="step">
              <span className="step-number">2</span>
              <h4>Plan</h4>
              <p>Develop actionable strategies and roadmaps</p>
            </div>
            <div className="step">
              <span className="step-number">3</span>
              <h4>Execute</h4>
              <p>Implementation support and guidance</p>
            </div>
            <div className="step">
              <span className="step-number">4</span>
              <h4>Scale</h4>
              <p>Optimise and expand successful initiatives</p>
            </div>
          </div>
        </div>
      </section>

      {/* Engagement Models */}
      <section className="engagement" id="pricing">
        <div className="container">
          <h3>Engagement Models</h3>
          <div className="engagement-grid">
            <div className="engagement-card">
              <h4>Project-Based</h4>
              <p className="price">Fixed Price</p>
              <p>Ideal for specific initiatives with clear scope</p>
              <ul>
                <li>Defined deliverables</li>
                <li>Fixed timeline</li>
                <li>Dedicated team</li>
              </ul>
              <a href="mailto:hello@databuilder.co.uk?subject=Working%20with%20Databuilder%20on%20a%20Project" className="button primary">Discuss Project</a>
            </div>
            <div className="engagement-card featured">
              <h4>Retainer</h4>
              <p className="price">From £3,000/month</p>
              <p>Ongoing strategic support and guidance</p>
              <ul>
                <li>Regular strategic sessions</li>
                <li>Priority access</li>
                <li>Flexible resource allocation</li>
              </ul>
              <a href="mailto:hello@databuilder.co.uk?subject=Retaining%20Databuilder%20for%20Data%20and%20AI%20expert%20advice" className="button primary">Learn More</a>
            </div>
            <div className="engagement-card">
              <h4>Interim Leadership</h4>
              <p className="price">Bespoke Cost</p>
              <p>Senior expertise for critical periods</p>
              <ul>
                <li>Experienced leadership</li>
                <li>Knowledge transfer</li>
                <li>Team development</li>
              </ul>
              <a href="mailto:hello@databuilder.co.uk?subject=Interim%20Leadership%20Opportunity%20for%20Databuilder" className="button primary">Contact Us</a>
            </div>
          </div>
        </div>
      </section>

      <footer className="App-footer">
        <div className="footer-content">
          <div className="footer-cta">
            <h3>Ready to Transform with AI?</h3>
            <a href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20Databuilder's%20Services" className="button primary">Get in Touch</a>
          </div>
          <p>© 2025 Databuilder Limited. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Consultancy;
