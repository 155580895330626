import React from 'react';
import '../index.css'; // Import the CSS file

function EPC() {
  return (
    <div id="root">
      {/* Header */}
      <header className="App-header">
        <h1>
          <a href="/" className="logo-container">
            <img src="images/databuilder-textless-logo.png" alt="Databuilder Limited" className="logo-image" />
            <span>Databuilder.</span>
          </a>
        </h1>
        <ul className="nav-links">
          <li><span className="current-page">EPC Complete</span></li>
          <li><a href="/consultancy">Consultancy Services</a></li>
          <li><a href="/about">About Us</a></li>
        </ul>
      </header>

      {/* Product Hero */}
      <section className="hero product-hero">
        <div className="hero-content">
          <h2>EPC Complete</h2>
          <p>Generate high-quality synthetic property data for better insights and decision-making</p>
          <div className="button-group">
            <a className="button primary" href="#pricing">View Pricing</a>
            <a className="button secondary" href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20EPC%20Complete">Contact Sales</a>
          </div>
        </div>
      </section>

      {/* Product Features */}
      <section className="features">
        <div className="container">
          <h3>Key Features</h3>
          <div className="feature-grid">
            <div className="feature-card">
              <h4>AI-Powered Generation</h4>
              <p>Advanced machine learning models ensure realistic and accurate synthetic data</p>
            </div>
            <div className="feature-card">
              <h4>Privacy Compliant</h4>
              <p>GDPR-compliant synthetic data generation that protects sensitive information</p>
            </div>
            <div className="feature-card">
              <h4>Scalable Solutions</h4>
              <p>Generate millions of synthetic records quickly and efficiently</p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="pricing" id="pricing">
        <div className="container">
          <h3>Pricing Plans</h3>
          <div className="pricing-grid">
            <div className="pricing-card">
              <h4>Starter</h4>
              <p className="price">£500<span>/month</span></p>
              <ul>
                <li>Up to 10,000 synthetic records</li>
                <li>Basic API access</li>
                <li>Email support</li>
              </ul>
              <a href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20EPC%20Complete%20and%20how%20I%20get%20started" className="button primary">Get Started</a>
            </div>
            <div className="pricing-card featured">
              <h4>Professional</h4>
              <p className="price">£2,000<span>/month</span></p>
              <ul>
                <li>Up to 100,000 synthetic records</li>
                <li>Advanced API access</li>
                <li>Priority support</li>
                <li>Custom data fields</li>
              </ul>
              <a href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20EPC%20Complete%20and%20how%20I%20get%20started" className="button primary">Contact Sales</a>
            </div>
            <div className="pricing-card">
              <h4>Enterprise</h4>
              <p className="price">Price on Enquiry</p>
              <ul>
                <li>Unlimited synthetic records</li>
                <li>Full API access</li>
                <li>24/7 support</li>
                <li>Custom integration</li>
                <li>Dedicated account manager</li>
              </ul>
              <a href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20EPC%20Complete%20and%20how%20I%20get%20started" className="button primary">Contact Sales</a>
            </div>
          </div>
        </div>
      </section>

      <footer className="App-footer">
        <div className="footer-content">
          <div className="footer-cta">
            <h3>Ready to Retrofit?</h3>
            <a href="mailto:hello@databuilder.co.uk?subject=Tell%20me%20more%20about%20Databuilder's%20Services" className="button primary">Get in Touch</a>
          </div>
          <p>© 2025 Databuilder Limited. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default EPC;
